@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.get-color-from-palette($primary-palette, 'default');

  .mat-toolbar.mat-primary {
    background: linear-gradient(90deg, $primary-color 15%, darken($primary-color, 4%) 100%);
  }

  .mat-mdc-tab-nav-bar.mat-background-primary {
    background: $primary-color;
  }
}
