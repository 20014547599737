@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  $accent-palette: map.get($color-config, 'accent');
  $accent-color: mat.get-color-from-palette($accent-palette, 'default');
  $foreground-palette: map.get($color-config, 'foreground');
  $foreground-text: map.get($foreground-palette, 'text');

  ul.scrollspy-nav {

    li {
      border-left: 2px solid $accent-color;
      box-shadow: inset 1px 0 0 $accent-color;

      &:not(.active) {
        box-shadow: inset 1px 0 0 rgba($foreground-text, .21);

        a:not(:hover) {
          color: $foreground-text;
        }
      }
    }
  }
}
